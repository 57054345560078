import React from 'react'
import { Link } from 'gatsby'

const Logo = ({ siteTitle }) => (
  <div className="c-logo">
    <Link className="c-logo__link" to="/">
      <svg
        width="118"
        height="64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="a"
          maskUnits="userSpaceOnUse"
          x="0"
          y="52"
          width="11"
          height="12"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 52.995h10.812v10.853H0V52.995z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#a)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.029 56.863a3.19 3.19 0 0 0-2.57-1.29c-1.627 0-2.676 1.355-2.676 2.86 0 1.633 1.178 2.837 2.741 2.837 1.049 0 1.97-.516 2.505-1.29v-3.117zm2.783 6.77H8.135v-.838c-.856.731-1.84 1.054-2.89 1.054-1.54 0-2.997-.689-3.918-1.763C.514 61.14 0 59.85 0 58.432c0-3.073 2.27-5.438 5.095-5.438 1.178 0 2.227.388 3.04 1.054v-.839h2.677v10.425z"
            fill="#BBB"
          />
        </g>
        <mask
          id="b"
          maskUnits="userSpaceOnUse"
          x="13"
          y="48"
          width="12"
          height="16"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.808 48.374H24.62v15.474H13.808V48.374z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#b)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.592 59.98a3.038 3.038 0 0 0 2.505 1.29c1.562 0 2.74-1.204 2.74-2.837 0-1.505-1.05-2.86-2.676-2.86-1.07 0-1.992.516-2.57 1.29v3.117zm-2.784 3.654v-15.26h2.784v5.588c.813-.645 1.798-.968 2.933-.968 2.826 0 5.095 2.365 5.095 5.439 0 1.418-.513 2.707-1.328 3.653-.92 1.074-2.376 1.763-3.917 1.763-1.049 0-2.034-.323-2.89-1.054v.838h-2.677z"
            fill="#BBB"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.082 60.452c.707.645 1.798 1.032 2.848 1.032.749 0 1.327-.365 1.327-.88 0-1.57-5.031-1.011-5.031-4.364 0-1.999 1.926-3.245 3.982-3.245 1.348 0 2.826.516 3.511 1.053l-1.306 2.02c-.536-.386-1.242-.709-1.991-.709-.77 0-1.413.3-1.413.838 0 1.354 5.031.817 5.031 4.427 0 2-1.948 3.224-4.132 3.224-1.435 0-2.954-.494-4.175-1.397l1.349-1.999zM43.781 63.633c-.364.13-1.199.215-1.734.215-1.67 0-2.89-.559-3.597-1.504-.535-.71-.77-1.634-.77-3.117v-3.654h-2.89V53.21h2.89v-2.901h2.782v2.9h3.426v2.364h-3.425v3.977c0 .645.106 1.032.342 1.311.279.322.728.516 1.413.516a6.72 6.72 0 0 0 1.135-.108l.428 2.364zM52.666 55.681c-.193-.064-.6-.107-1.007-.107-1.22 0-2.183.881-2.526 1.504v6.556h-2.784V53.21h2.677v1.053c.621-.774 1.756-1.268 3.019-1.268.406 0 .642.043.942.085l-.321 2.601z"
          fill="#BBB"
        />
        <mask
          id="c"
          maskUnits="userSpaceOnUse"
          x="53"
          y="52"
          width="12"
          height="12"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.736 52.995h10.812v10.853H53.736V52.995z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#c)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61.765 56.863a3.19 3.19 0 0 0-2.57-1.29c-1.627 0-2.676 1.355-2.676 2.86 0 1.633 1.178 2.837 2.74 2.837a3.04 3.04 0 0 0 2.506-1.29v-3.117zm2.783 6.77h-2.677v-.838c-.856.731-1.841 1.054-2.89 1.054-1.54 0-2.997-.689-3.918-1.763-.813-.946-1.327-2.235-1.327-3.654 0-3.073 2.269-5.438 5.095-5.438 1.178 0 2.227.388 3.04 1.054v-.839h2.677v10.425z"
            fill="#BBB"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M76.964 61.506c-1.22 1.633-2.76 2.342-4.71 2.342-3.21 0-5.566-2.406-5.566-5.416 0-3.03 2.42-5.437 5.587-5.437 1.799 0 3.405.838 4.368 2.084l-1.97 1.741c-.556-.73-1.39-1.246-2.398-1.246-1.605 0-2.803 1.268-2.803 2.858 0 1.634 1.177 2.837 2.868 2.837 1.135 0 2.034-.687 2.483-1.375l2.141 1.612zM86.706 63.633c-.365.13-1.2.215-1.735.215-1.67 0-2.89-.559-3.596-1.504-.536-.71-.771-1.634-.771-3.117v-3.654h-2.89V53.21h2.89v-2.901h2.782v2.9h3.426v2.364h-3.426v3.977c0 .645.108 1.032.344 1.311.278.322.727.516 1.412.516.408 0 .943-.064 1.135-.108l.428 2.364z"
          fill="#BBB"
        />
        <mask
          id="d"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="118"
          height="64"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 63.848h117.663V0H0v63.848z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#d)" fillRule="evenodd" clipRule="evenodd" fill="#BBB">
          <path d="M89.274 63.633h2.783V53.21h-2.783v10.423zm1.391-15.904c1.007 0 1.82.774 1.82 1.784s-.813 1.784-1.82 1.784c-1.005 0-1.82-.773-1.82-1.784 0-1.01.815-1.784 1.82-1.784zM99.722 61.27c1.626 0 2.74-1.269 2.74-2.837 0-1.57-1.114-2.86-2.74-2.86-1.628 0-2.74 1.29-2.74 2.86 0 1.568 1.112 2.836 2.74 2.836zm0-8.276c3.146 0 5.523 2.43 5.523 5.439 0 3.008-2.377 5.416-5.523 5.416-3.148 0-5.524-2.408-5.524-5.416 0-3.01 2.376-5.439 5.524-5.439zM107.386 63.633V53.21h2.676v.838a4.567 4.567 0 0 1 2.933-1.053c1.435 0 2.741.645 3.597 1.676.685.816 1.071 1.87 1.071 3.654v5.308h-2.784V58.11c0-1.032-.235-1.57-.62-1.956-.364-.366-.921-.58-1.564-.58-1.177 0-2.14.752-2.526 1.289v6.77h-2.783zM73.234 5.842C69.48 2.075 64.49 0 59.184 0S48.888 2.075 45.135 5.842c-3.753 3.768-5.819 8.776-5.819 14.104 0 .54.021 1.079.064 1.611a59.067 59.067 0 0 1 3.23-1.017c-.007-.198-.01-.396-.01-.594 0-9.18 7.44-16.649 16.584-16.649 9.145 0 16.585 7.469 16.585 16.649 0 .173-.003.345-.008.516a59.554 59.554 0 0 1 3.235 1.003c.037-.503.056-1.01.056-1.52 0-5.327-2.066-10.335-5.819-14.103z" />
          <path d="M59.184 36.594c-6.955 0-12.922-4.32-15.382-10.427a58.06 58.06 0 0 1 15.52-2.1c5.188 0 10.317.683 15.272 2.031-2.442 6.144-8.43 10.496-15.41 10.496zm23.253-11.313a60.43 60.43 0 0 0-8.988-2.873 61.415 61.415 0 0 0-14.126-1.638 61.437 61.437 0 0 0-16.442 2.232 57.598 57.598 0 0 0-3.162.972 60.212 60.212 0 0 0-4.871 1.88l1.309 3.023a57.915 57.915 0 0 1 4.486-1.736c.983 2.56 2.5 4.909 4.492 6.908 3.753 3.767 8.742 5.842 14.05 5.842 5.306 0 10.295-2.075 14.048-5.842a19.897 19.897 0 0 0 4.525-6.991 57.285 57.285 0 0 1 4.46 1.702l1.295-3.03a53.476 53.476 0 0 0-1.076-.45z" />
        </g>
      </svg>
    </Link>
  </div>
)

export default Logo
